<!-- This example requires Tailwind CSS v2.0+ -->
<template>
  <div class="font-body">
    <div class="max-w-7xl mx-auto py-8 px-4 sm:py-24 sm:px-6 lg:px-8">
      <div class="text-center">
        <h2 class="mt-1 text-4xl font-extrabold text-white sm:text-5xl sm:tracking-tight lg:text-6xl">Carolina Fagioli</h2>
<!--        <p class="max-w-xl mt-5 mx-auto text-xl text-white italic">in breve...</p>-->
      </div>
    </div>
  </div>

  <section class="bg-white overflow-hidden rounded-lg shadow-lg lg:mt-10">
    <div class="relative max-w-7xl mx-auto pt-20 pb-12 px-4 sm:px-6 lg:px-8 lg:py-20">
      <div class="relative lg:flex ">
        <div class="hidden lg:block lg:flex-shrink-0 rounded-full border-8 border-gray-300 shadow-xl">
          <img class="h-64 w-64 rounded-full xl:h-80 xl:w-80" src="../assets/ka.png" alt="" />
        </div>

        <div class="lg:hidden flex justify-center w-full mb-5">
          <img class="h-24 w-24 rounded-full drop-shadow-xl" src="../assets/ka.png" alt="" />
        </div>

        <div class="relative lg:ml-10">
          <blockquote class="relative">
            <div class="leading-7 font-medium text-gray-900 text-left font-body">
              <p>Entusiasta digitale sin dalla realizzazione del primo calendario, progettato in basic sullo Spectrum
                48k del Babbo e stampato sulla carta termica della stampante da 4 pollici; legata al sociale dalla prima
                tessera WWF junior, regalo della Mamma.</p>
              <p>Un percorso di curiosità tutt’altro che lineare mi ha fatto approdare al digital fundraising, dove -
                forse - getterò l'ancora: la passione per l'arte e la tecnologia mi ha portato una laurea in
                architettura, evolutasi nella professione di graphic designer, quella per l’innovazione mi ha
                condotto al web marketing; applicare le mie competenze nel sociale è stato naturale.</p>
              <p>Vivo in viaggio, perennemente connessa: d'altra parte, siamo on line per costruire relazioni.</p>
            </div>
            <footer class="mt-8">
              <div class="flex justify-end">
                <div class="ml-4 lg:ml-0">
                  <div class="text-base font-medium font-bold text-right">
                    <a href="https://twitter.com/carolinafagioli">
                        <span class="inline-flex items-center justify-center h-6 w-6 rounded-full align-middle" style="background-color: #1DA1F2">
                          <svg class="stroke-white fill-white h-4" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <title>Twitter</title>
                            <path d="M23.953 4.57a10 10 0 01-2.825.775 4.958 4.958 0 002.163-2.723c-.951.555-2.005.959-3.127 1.184a4.92 4.92 0 00-8.384 4.482C7.69 8.095 4.067 6.13 1.64 3.162a4.822 4.822 0 00-.666 2.475c0 1.71.87 3.213 2.188 4.096a4.904 4.904 0 01-2.228-.616v.06a4.923 4.923 0 003.946 4.827 4.996 4.996 0 01-2.212.085 4.936 4.936 0 004.604 3.417 9.867 9.867 0 01-6.102 2.105c-.39 0-.779-.023-1.17-.067a13.995 13.995 0 007.557 2.209c9.053 0 13.998-7.496 13.998-13.985 0-.21 0-.42-.015-.63A9.935 9.935 0 0024 4.59z"/>
                          </svg>
                        </span></a>
                    &nbsp;
                    <a href="https://www.linkedin.com/in/carolinafagioli/">
                        <span class="inline-flex items-center justify-center h-6 w-6 rounded align-middle overflow-clip">
                          <svg style="stroke: #0A66C2; fill: #0A66C2;" class="" role="img" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><title>LinkedIn</title><path d="M20.447 20.452h-3.554v-5.569c0-1.328-.027-3.037-1.852-3.037-1.853 0-2.136 1.445-2.136 2.939v5.667H9.351V9h3.414v1.561h.046c.477-.9 1.637-1.85 3.37-1.85 3.601 0 4.267 2.37 4.267 5.455v6.286zM5.337 7.433c-1.144 0-2.063-.926-2.063-2.065 0-1.138.92-2.063 2.063-2.063 1.14 0 2.064.925 2.064 2.063 0 1.139-.925 2.065-2.064 2.065zm1.782 13.019H3.555V9h3.564v11.452zM22.225 0H1.771C.792 0 0 .774 0 1.729v20.542C0 23.227.792 24 1.771 24h20.451C23.2 24 24 23.227 24 22.271V1.729C24 .774 23.2 0 22.222 0h.003z"/></svg>
                        </span></a>
                  </div>
                </div>
              </div>
            </footer>
          </blockquote>
        </div>
      </div>
    </div>
  </section>
</template>